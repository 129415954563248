import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store"
export default function* watcherGetPendingTasksSaga() {
  yield takeEvery("LOAD_PENDING_TASK", workerSaga);
}

function* workerSaga(action) {
  try {
    yield put({ type: "DISPLAY_LOADER" ,payload:true }); 
    let payload = {};
    yield request(
      "get",
      action.payload,
      `api/v1/task/reviewer?reviewerId=${store.getState().persistReducer.userId}&pageNumber=${action.payload.pageNumber}&pageSize=10`
    ).then((response) => {
      payload = response;
    });
    window.reviewer = payload;
    yield put({ type: "PENDING_TASK_TO_DO", payload: payload.items });
    yield put({ type: 'CURRENT_PAGE', payload: payload.currentPage });
    yield put({ type: 'TOTAL_PAGES', payload: payload.totalPages });
    yield put({ type: 'TOTAL_ITEMS', payload: payload.totalItems });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  } catch (e) {
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("pending-task-user-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER" ,payload:false }); 
  }
}
