import { takeEvery, put } from "redux-saga/effects";
import { request } from "../../../api/Service";
import { getErrorMessage } from "../../../utils/errorHandler";
import { toast } from "react-toastify";
import { store } from "../../store"

export default function* watcherGetDashboardSaga() {
  yield takeEvery("LOAD_DASHBOARD", workerSaga);
}

function* workerSaga(action) {
  try {
    let payload = {};
    yield put({ type: "DISPLAY_LOADER", payload: true });
    if (store.getState().persistReducer.userType === 'ADMIN') {
      yield request("get", action.payload, `api/v1/home/?userId=${store.getState().persistReducer.userId}`).then((response) => {
        payload = response;
      });
      yield put({ type: "DASHBOARD", payload: payload.response });
      yield put({ type: "PERSIST_STAFF_DETAILS", payload: payload.response });
    } else if (store.getState().persistReducer.userType === "Staff") {
      yield request("get", action.payload, `api/v1/home/?userId=${store.getState().persistReducer.userId}`).then((response) => {
        payload = response;
      });
      yield put({ type: "PERSIST_STAFF_DETAILS", payload: payload.response });
      yield put({ type: "DASHBOARD", payload: payload.response });
    }
    else if (store.getState().persistReducer.userType === "SUPER_ADMIN") {
      yield request("get", action.payload, `api/v1/home/?userId=${store.getState().persistReducer.userId}`).then((response) => {
        payload = response;
      });
      if (payload.response.todayJob == null) {
        payload.response.todayJob = [];
      }
      if (payload.response.todayTask == null) {
        payload.response.todayTask = [];
      }
      yield put({ type: "DASHBOARD", payload: payload.response });
      yield put({ type: "LOADING_BUTTON_SPINNERS", payload: false });
    }
    yield put({ type: "DISPLAY_LOADER" });
  } catch (e) {
    console.log(e)
    yield put({ type: "LOADING_BUTTON_SPINNER" });
    console.log("dashboard-saga", e.response);
    console.log(e.response);
    console.log(e.response);
    const errorMessage = getErrorMessage(e.response);
    toast.error(errorMessage);
    yield put({ type: "API_ERRORED", payload: e });
    yield put({ type: "DISPLAY_LOADER", payload: false });
  }
}